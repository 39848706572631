import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { accessTokenSelector } from "src/store/slices/auth";

export default function ProtectedRoutes() {
  const location = useLocation();
  const accessToken = useSelector(accessTokenSelector);

  type Context = [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  const [collapsed, setCollapsed] = useOutletContext<Context>() || [false];
  console.log("accessToken", accessToken);
  if (accessToken) {
    return <Outlet context={[collapsed, setCollapsed]} />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
