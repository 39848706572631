import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  InputRef,
  Select,
  Table,
  TableColumnType,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { FilterDropdownProps, FilterValue } from "antd/es/table/interface";
import clsx from "clsx";
import dayjs from "dayjs";
import Papa from "papaparse";
import { useRef, useState } from "react";
import { DropdownIcon } from "src/assets/icons/DropdownIcon";
import { TRANSACTION_STATUSES } from "src/components/constants";
import ConfirmModal from "src/components/Modals/ConfirmModal";
import SentTranscation from "src/components/SentTranscation/SentTranscation";
import { Transaction } from "src/models/TransactionModel";
import {
  useCancelTranscationMutation,
  useGetDashboardTransactionsQuery,
} from "src/store/apis/Transactions";

type DataIndex = keyof Transaction;
type OnChange = NonNullable<TableProps<Transaction>["onChange"]>;
type Filters = Parameters<OnChange>[1];

export default function TransactionsGrid() {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const searchInput = useRef<InputRef>(null);

  const [isModelOpen, setisModelOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [currentAction, setCurrentAction] = useState<string | null>(null);
  const [currentRecord, setCurrentRecord] = useState<DataType | null>(null);
  const [filtersInfo, setfiltersInfo] = useState<Filters>({});

  const [
    cancelTranscation,
    { isLoading: isLoadingCancel, isSuccess: isSuccessCancel },
  ] = useCancelTranscationMutation();

  const constructFilterString = (filters: Record<string, string>): string => {
    return Object.keys(filters)
      .map((key) => {
        if (key === "dateCreated") {
          const [startDate, endDate] = filters[key].split("<>");
          return `${key} >= ${startDate} && ${key} <= ${endDate}`;
        } else if (key === "transactionStatusId") {
          return `${key} == ${filters[key]}`;
        } else if (key === "redemptionErrorCode") {
          const value = filters[key].split("<>")[0];
          const operator = filters[key].split("<>")[1]
            ? filters[key].split("<>")[1]
            : "==";
          return `${key} ${operator} ${value}`;
        } else {
          return `${key} Contains ${filters[key]}`;
        }
      })
      .join(" && ");
  };

  const { data, isLoading, isFetching } = useGetDashboardTransactionsQuery({
    pageNumber: currentPage,
    pageSize: itemsPerPage,
    filter: constructFilterString(filters),
  });
  type OnChange = NonNullable<TableProps<Transaction>["onChange"]>;
  const handleOnChange: OnChange = (pagination, filters) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setfiltersInfo(filters);
    setCurrentPage(pagination.current || 1);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setFilters({
      ...filters,
      [dataIndex]:
        selectedKeys[0] + (selectedKeys[1] ? "<>" + selectedKeys[1] : ""),
    });
  };

  const handleReset = (
    dataIndex: DataIndex,
    confirm: FilterDropdownProps["confirm"]
  ) => {
    const newFilters = { ...filters };
    delete newFilters[dataIndex];
    setFilters(newFilters);
    confirm();
  };
  const ResetFilters = () => {
    setfiltersInfo({});
    setFilters({});
  };

  const showModal = (action: string, record: DataType) => {
    setCurrentAction(action);
    setCurrentRecord(record);

    if (action === "cancel") {
      setModalContent(
        <div>
          <h2>
            Are you sure you want to cancel this transction {record.requestId}
          </h2>
        </div>
      );
    } else if (action === "sent") {
      setModalContent(
        <SentTranscation
          handleRadioChange={(value) => {
            setSelectedValue(+value);
          }}
        />
      );
    }
    setisModelOpen(true);
  };
  const handelOK = () => {
    console.log("okkkkkkkkk", currentAction, currentRecord, selectedValue);
    if (currentAction === "cancel") {
      cancelTranscation({ orderId: currentRecord?.siebelOrderId });
    }
    if (currentAction === "sent") {
    }
    // setisModelOpen(false);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    searchType: "text" | "date" | "select" | "textWithSelect" = "text"
  ): TableColumnType<Transaction> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div className="p-4 flex flex-col gap-2 items-center justify-center">
          {searchType === "text" && (
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              className="px-3 py-2 border-2"
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ width: 250, display: "block" }}
            />
          )}
          {searchType === "textWithSelect" && (
            <div className="flex gap-2 w-full">
              <Select
                placeholder={"Type"}
                className="custom-select [&_.ant-select-selector]:!h-10"
                value={selectedKeys[1]}
                defaultValue={"=="}
                onChange={(value) =>
                  setSelectedKeys([selectedKeys[0], value ? value : ""])
                }
                style={{ width: 100, display: "block" }}
                suffixIcon={<DropdownIcon />}
              >
                <Select.Option key={"equal"} value={"=="}>
                  Equal
                </Select.Option>
                <Select.Option key={"notEqual"} value={"!="}>
                  Not Equal
                </Select.Option>
              </Select>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                className="px-3 py-2 border-2"
                onChange={(e) =>
                  setSelectedKeys(
                    e.target.value ? [e.target.value, selectedKeys[1]] : []
                  )
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                style={{ width: 250, display: "block" }}
              />
            </div>
          )}
          {searchType === "select" && (
            <Select
              placeholder={`Search ${dataIndex}`}
              className="custom-select [&_.ant-select-selector]:!h-10"
              value={selectedKeys[0]}
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              style={{ width: 250, display: "block" }}
              suffixIcon={<DropdownIcon />}
            >
              {Object.entries(TRANSACTION_STATUSES).map(([key, status]) => (
                <Select.Option key={key} value={key}>
                  {status}
                </Select.Option>
              ))}
            </Select>
          )}
          {searchType === "date" && (
            <>
              <DatePicker
                placeholder="Start Date"
                className="border-2 h-10 w-full flex items-center justify-center"
                format={"[From]\t\tYYYY-MM-DD HH:mm"}
                value={
                  selectedKeys[0] && selectedKeys[0].toString().split("<>")[0]
                    ? dayjs(selectedKeys[0].toString().split("<>")[0])
                    : null
                }
                onChange={(date) => {
                  const endDate = selectedKeys[0]
                    ? selectedKeys[0].toString().split("<>")[1]
                    : "";
                  const formattedDate = date
                    ? dayjs(date).second(0).millisecond(0).toISOString()
                    : "";
                  setSelectedKeys(date ? [`${formattedDate}<>${endDate}`] : []);
                }}
                onOk={(date) => {
                  const endDate = selectedKeys[0]
                    ? selectedKeys[0].toString().split("<>")[1]
                    : "";
                  const formattedDate = date
                    ? dayjs(date).second(0).millisecond(0).toISOString()
                    : "";
                  setSelectedKeys(date ? [`${formattedDate}<>${endDate}`] : []);
                }}
                style={{ width: 250, display: "block" }}
                showTime={{
                  format: "HH:mm",
                  use12Hours: true,
                }}
              />
              <DatePicker
                placeholder="End Date"
                className="border-2 h-10 w-full flex items-center justify-center"
                format={"[To]\t\tYYYY-MM-DD HH:mm"}
                value={
                  selectedKeys[0] && selectedKeys[0].toString().split("<>")[1]
                    ? dayjs(selectedKeys[0].toString().split("<>")[1])
                    : null
                }
                onChange={(date) => {
                  const startDate = selectedKeys[0]
                    ? selectedKeys[0].toString().split("<>")[0]
                    : "";
                  const formattedDate = date
                    ? dayjs(date).second(0).millisecond(0).toISOString()
                    : "";
                  setSelectedKeys(
                    date ? [`${startDate}<>${formattedDate}`] : []
                  );
                }}
                onOk={(date) => {
                  const startDate = selectedKeys[0]
                    ? selectedKeys[0].toString().split("<>")[0]
                    : "";
                  const formattedDate = date
                    ? dayjs(date).second(0).millisecond(0).toISOString()
                    : "";
                  setSelectedKeys(
                    date ? [`${startDate}<>${formattedDate}`] : []
                  );
                }}
                style={{ width: 250, display: "block" }}
                showTime={{
                  format: "HH:mm",
                  use12Hours: true,
                }}
              />
            </>
          )}

          <div className="w-full flex items-center justify-center gap-2">
            <Button
              className="w-full rounded-lg text-center bg-primary px-2 py-1 h-full text-16 text-white"
              onClick={() => {
                handleSearch(selectedKeys as string[], confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              className="w-full rounded-lg border-1 border-solid border-primary bg-transparent text-16 font-medium px-2 py-1 h-full text-primary hover:bg-primary/10 disabled:grayscale"
              size="small"
              style={{ width: 90 }}
              onClick={() => {
                setSelectedKeys([]);
                clearFilters && handleReset(dataIndex, confirm);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => {
      return (
        <>
          {searchType === "text" ? (
            <SearchOutlined
              style={{ color: filtered ? "#1677ff" : undefined }}
            />
          ) : (
            <FilterFilled style={{ color: filtered ? "#1677ff" : undefined }} />
          )}
        </>
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const dataTableColumn: ColumnsType<Transaction> = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      ellipsis: true,
      filteredValue: filtersInfo?.dateCreated || null,
      sorter: (a: DataType, b: DataType) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (value) => (
        <span>{value ? dayjs(value).format("DD/MM/YYYY hh:mmA") : "_"}</span>
      ),
      ...getColumnSearchProps("dateCreated", "date"),
    },
    {
      title: "Status",
      dataIndex: "transactionStatusId",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.transactionStatusId || null,
      render: (value: number) => {
        return (
          <Tag
            className={clsx({
              "rounded-sm text-12 border-0 font-bold uppercase": true,
              "bg-[#000000]/20 text-[#000000]": value === 1,
              "bg-[#1FB981]/20 text-[#1FB981]": value === 2,
              "bg-[#E10A0A]/20 text-[#E10A0A]":
                value === 3 || value === 4 || value === 5,
            })}
          >
            {TRANSACTION_STATUSES[value]}
          </Tag>
        );
      },
      ...getColumnSearchProps("transactionStatusId", "select"),
    },
    {
      title: "Merchant Name",
      dataIndex: "merchantNameEn",
      ellipsis: true,
      filteredValue: filtersInfo?.merchantNameEn || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("merchantNameEn"),
    },
    {
      title: "Customer ID",
      dataIndex: "customerId",
      ellipsis: true,
      filteredValue: filtersInfo?.customerId || null,
      align: "center",
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("customerId"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      ellipsis: true,
      filteredValue: filtersInfo?.mobileNumber || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("mobileNumber"),
    },
    {
      title: "Provider Channel",
      dataIndex: "providerChannel",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.providerChannel || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("providerChannel"),
    },
    {
      title: "User Channel",
      dataIndex: "userChannel",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.userChannel || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("userChannel"),
    },
    {
      title: "Siebel Order ID",
      dataIndex: "siebelOrderId",
      ellipsis: true,
      filteredValue: filtersInfo?.siebelOrderId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("siebelOrderId"),
    },
    {
      title: "Redemption Error Message",
      dataIndex: "redemptionErrorMessage",
      ellipsis: true,
      filteredValue: filtersInfo?.redemptionErrorMessage || null,
      render: (value) => (
        <Tooltip trigger={["click"]} title={value && value}>
          <span className="max-w-[250px] inline-block truncate">
            {value ? value : "_"}
          </span>
        </Tooltip>
      ),
      ...getColumnSearchProps("redemptionErrorMessage"),
    },
    {
      title: "Redemption Error Code",
      dataIndex: "redemptionErrorCode",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.redemptionErrorCode || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("redemptionErrorCode", "textWithSelect"),
    },
    {
      title: "Order Title",
      dataIndex: "orderTitleEn",
      ellipsis: true,
      filteredValue: filtersInfo?.orderTitleEn || null,
      render: (value) => (
        <Tooltip trigger={["click"]} title={value && value}>
          <span className="max-w-[250px] inline-block truncate">
            {value ? value : "_"}
          </span>
        </Tooltip>
      ),
      ...getColumnSearchProps("orderTitleEn"),
    },
    {
      title: "Offer Number",
      dataIndex: "offerNumber",
      ellipsis: true,
      filteredValue: filtersInfo?.offerNumber || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("offerNumber"),
    },
    {
      title: "Offer Project Value",
      dataIndex: "offerProjectValue",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.offerProjectValue || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("offerProjectValue"),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.userId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("userId"),
    },
    {
      title: "External Transaction Id",
      dataIndex: "externalTransactionId",
      ellipsis: true,
      filteredValue: filtersInfo?.externalTransactionId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("externalTransactionId"),
    },
    {
      title: "Redeemed Points",
      dataIndex: "redeemedPoints",
      ellipsis: true,
      filteredValue: filtersInfo?.redeemedPoints || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("redeemedPoints"),
    },
    {
      title: "Value",
      dataIndex: "sarValue",
      ellipsis: true,
      filteredValue: filtersInfo?.sarValue || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Offer Discount",
      dataIndex: "offerDiscount",
      ellipsis: true,
      filteredValue: filtersInfo?.offerDiscount || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      filteredValue: filtersInfo?.email || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Request ID",
      dataIndex: "requestId",
      ellipsis: true,
      filteredValue: filtersInfo?.requestId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Secondary Email",
      dataIndex: "secondaryEmail",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.secondaryEmail || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Merchant ID",
      dataIndex: "externalMerchantId",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.externalMerchantId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Membership ID",
      dataIndex: "membershipId",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.membershipId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
      ...getColumnSearchProps("membershipId"),
    },
    {
      title: "Date Modified",
      dataIndex: "dateModified",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.dateModified || null,
      render: (value) => (
        <span>{value ? dayjs(value).format("DD/MM/YYYY hh:mmA") : "_"}</span>
      ),
    },
    {
      title: "Voucher Expiry Date",
      dataIndex: "voucherExpiryDate",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.voucherExpiryDate || null,
      render: (value) => (
        <span>{value ? dayjs(value).format("DD/MM/YYYY hh:mmA") : "_"}</span>
      ),
    },
    {
      title: "Is Points Redeemed",
      dataIndex: "isPointsRedeemed",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.isPointsRedeemed || null,
      render: (value) => <span>{value ? "Yes" : "No"}</span>,
    },
    {
      title: "Is Voucher Subscribed",
      dataIndex: "isVoucherSubscribed",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.isVoucherSubscribed || null,
      render: (value) => <span>{value ? "Yes" : "No"}</span>,
    },
    {
      title: "Offer Id",
      dataIndex: "externalOfferId",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.externalOfferId || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Voucher Code",
      dataIndex: "voucherCode",
      ellipsis: true,
      align: "center",
      filteredValue: filtersInfo?.voucherCode || null,
      render: (value) => <span>{value ? value : "_"}</span>,
    },
    {
      title: "Actions",
      fixed: "right",
      ellipsis: true,
      align: "center",
      render: (record) => {
        const isCancelDisabled = !(
          (record.redemptionErrorCode === "3" ||
            (record.isPointsRedeemed === true &&
              record.isVoucherSubscribed === false)) &&
          record.transactionStatusId !== 3
        );

        return (
          <div className="flex justify-between gap-4">
            <Button
              className={clsx(
                "rounded-sm text-12 border-0 font-bold uppercase bg-primary text-white disabled:bg-gray-500"
              )}
              onClick={() => showModal("cancel", record)}
              disabled={isCancelDisabled}
            >
              Cancel
            </Button>
          </div>
        );
      },
    },
  ];

  type DataType = Record<string, any>;

  const exportToCSV = (data: DataType[], filename: string): void => {
    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleExport = () => {
    exportToCSV(data?.items, "table-data.csv");
  };

  return (
    <div className="custom-table-container custom-pagination">
      <div className="flex justify-end">
        <Button
          onClick={handleExport}
          type="default"
          style={{ marginBottom: 16 }}
          className="bg-primary text-white"
        >
          Export to CSV
        </Button>
        <Button
          onClick={() => {
            ResetFilters();
          }}
          type="default"
          style={{ marginBottom: 16 }}
          className="bg-primary text-white"
        >
          Reset All
        </Button>
      </div>

      <Table
        rowKey="id"
        size="small"
        loading={isLoading || isFetching}
        onChange={handleOnChange}
        showSorterTooltip={true}
        columns={dataTableColumn}
        dataSource={data?.items}
        scroll={{
          x: true,
          y: "70vh",
        }}
        pagination={{
          position: ["bottomCenter"],
          total: data?.paginator.totalItems,
          pageSize: itemsPerPage,
          current: currentPage,
          showSizeChanger: false,
          showQuickJumper: false,
          showPrevNextJumpers: false,
          className: "custom-pagination",
          size: "default",
          showTotal: (total) => {
            return <div className="p-4"> total : {total}</div>;
          },
        }}
      />

      <ConfirmModal
        isOpen={isModelOpen}
        onClose={() => {
          setisModelOpen(false);
        }}
        onConfirm={handelOK}
        isLoadingCancel={isLoadingCancel}
        isSuccessCancel={isSuccessCancel}
      >
        {modalContent}
      </ConfirmModal>
    </div>
  );
}
