// import { AUTH_TOKEN_KEY } from "src/HOC/withToken";

export const authInterceptor = (headers: Headers) => {
  const API_KEY = localStorage.getItem("AUTH_TOKEN_KEY");
  // headers.set("X-API-KEY", API_KEY);
  // console.log("API_KEY", API_KEY);
  // headers.set("X-API-KEY", String(API_KEY));
  headers.set("X-API-KEY", "amFyaXI6N0N6TTNmc0NGZzgrMzBUOVdDT0RQdz09");
  return headers;
};

export interface ApiResponse {
  result: any;
  message: string;
  statusCode: number;
  statusName: string;
}
