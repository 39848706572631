export const DropdownIcon = () => (
  <span className="inline-flex items-center justify-center w-5 h-5">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#F4F6FA" />
      <path
        d="M13 8.5C13 8.29 12.835 8.125 12.625 8.125H7.375C7.165 8.125 7 8.29 7 8.5C7 8.5975 7.0375 8.68 7.0975 8.7475L9.7225 11.7475C9.79 11.8225 9.8875 11.875 10 11.875C10.1125 11.875 10.21 11.8225 10.2775 11.7475L12.9025 8.7475C12.9625 8.68 13 8.5975 13 8.5Z"
        fill="#8F95B2"
      />
    </svg>
  </span>
);
