import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";
import ErrorAlert from "src/components/ErrorAlert/ErrorAlert";
import WelcomeSplash from "src/components/WelcomeSplash/WelcomeSplash";
import logoDark from "../../assets/logo192.png";
import { useLoginMutation } from "src/store/apis/Login";
import { login } from "src/store/slices/auth";

export default function Login() {
  const [loginUser, { isError, isLoading, error }] = useLoginMutation();

  const [submissionError, setSubmissionError] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChange = () => {
    setSubmissionError(false);
  };

  const onFinish = async (values: any) => {
    try {
      const result = await loginUser({
        userName: values.userName,
        password: values.password,
      }).unwrap();

      dispatch(
        login({
          user: {},
          accessToken: result.result.token,
          rememberMe: false,
        })
      );
      navigate("/");
    } catch (e) {
      console.error(e);
      setSubmissionError(true);
    }
  };

  return (
    <div className="flex h-screen">
      <WelcomeSplash />
      <div className=" w-full flex flex-col justify-center items-center lg:w-2/5">
        <div className="flex h-1/5 w-full p-4 py-[2.75rem] xxs:px-12 sm:px-[7rem] lg:hidden">
          <img
            src={logoDark}
            alt="Dsquares"
            className="max-h-[2.5rem] max-w-[12.25rem] flex-shrink-0 pb-0"
          />
        </div>
        <div className="flex w-full">
          <Form
            className="flex w-full flex-col gap-1 p-4 xxs:px-12 sm:px-[7rem]"
            initialValues={{ rememberMe: false }}
            onFinish={onFinish}
            onValuesChange={onChange}
          >
            <h1 className="font-poppins text-display-xxl text-dark">Login</h1>
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: <ErrorAlert message="Please enter your username" />,
                },
              ]}
            >
              <Input
                id="userName"
                className="input-field-ant mt-9"
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: <ErrorAlert message="Please enter your password" />,
                },
              ]}
            >
              <Input.Password
                type="password"
                id="password"
                className="input-field-ant"
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeFilled style={{ fontSize: "1.5rem" }} />
                  ) : (
                    <EyeInvisibleFilled style={{ fontSize: "1.5rem" }} />
                  )
                }
                visibilityToggle
              />
            </Form.Item>
            {isError && submissionError && (
              <ErrorAlert
                message={
                  (isError && (error as any).data?.message) ||
                  "Something went wrong"
                }
              />
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button mt-5 w-full bg-accent text-white disabled:bg-gray-400 bg-primary"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Login"}
              </Button>
            </Form.Item>
            <div className="mt-5 flex w-full flex-col items-center justify-between gap-4 xs:flex-row xs:gap-0">
              <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                <label className="text-center text-heading-1 font-medium text-primary">
                  <Checkbox id="remember-me" /> Remember me
                </label>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
