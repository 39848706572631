import { Spin } from "antd";

type Props = {
  isLoading: boolean;
};
export default function SplashScreen({ isLoading }: Props) {
  return (
    <Spin
      size="large"
      spinning={isLoading}
      indicator={
        <span className="flex h-20 w-20 animate-pulse items-center justify-center">
          <DsquaresLogo />
        </span>
      }
    >
      <div style={{ height: "100vh", width: "100vw" }} />
    </Spin>
  );
}

const DsquaresLogo = () => (
  <svg
    width="500"
    height="530"
    viewBox="0 0 500 530"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M295.726 278.163V164.568H91V482.889H409.321V278.163H295.726ZM295.676 373.712H200.177V278.213H295.676V373.712Z"
      fill="#0071CE"
    />
    <path d="M407.509 47H295.725V158.784H407.509V47Z" fill="#0071CE" />
  </svg>
);
