import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import Config from "../../config.json";
import { authInterceptor } from "src/store/utils";
import { logout } from "src/store/slices/auth";

const baseUrl = Config.REACT_APP_API_URL;

async function baseQuery(args: any, api: any, extraOptions: any) {
  const baseQueryApi = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: authInterceptor,
  });

  // Make the actual request
  const result = await baseQueryApi(args, api, extraOptions);

  // Check for a 401 status code
  if (result.error && result.error.status === 401) {
    // Dispatch the logout action to handle the 401
    api.dispatch(logout());

    return {
      error: {
        status: 401,
        data: "Unauthorized",
      },
    };
    
  }

  return result;
}

export default baseQuery;
