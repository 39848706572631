import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiResponse } from "../utils";
import Config from "../../config.json";
import { LoginModelReqTypes, LoginModelResTypes } from "src/models/LoginModel";

const baseUrl = Config.REACT_APP_API_URL;

const LoginApi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (builder) => ({
    Login: builder.mutation<LoginModelResTypes, LoginModelReqTypes>({
      query: ({ userName, password }) => ({
        url: `/Dashboard/GetToken`,
        method: "POST",
        body: {
          data: {
            userName: userName,
            password: password,
          },
        },
      }),
      transformResponse: (response: LoginModelResTypes) => response,
    }),
  }),
});

export const { useLoginMutation } = LoginApi;
export default LoginApi;
