import { BrowserRouter } from "react-router-dom";

import { ConfigProvider } from "antd";
import AppRoute from "src/router";
import { ToastProvider } from "src/context/ToastContext";
import { useZoomLevel } from "src/context/ZoomLevelContext";
import { withToken } from "src/HOC/withToken";

function App() {
  const { getRelativePX } = useZoomLevel();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: getRelativePX(14),
          colorPrimary: "#2A7DE1",
          colorBorder: "#D9E2EA",
          controlHeightLG: getRelativePX(48),
          sizeUnit: getRelativePX(4),
          sizeStep: getRelativePX(4),
          borderRadius: getRelativePX(6),
        },
      }}
    >
      <ToastProvider>
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </ToastProvider>
    </ConfigProvider>
  );
}

export default withToken(App);
