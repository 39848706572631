import { Layout as AntDLayout } from "antd";
import { Outlet } from "react-router-dom";
import SideBar from "src/components/SideBar/SideBar";
import { useState } from "react";

const { Content } = AntDLayout;

export default function Layout() {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <AntDLayout hasSider>
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntDLayout
        style={{
          marginLeft: collapsed ? 80 : 250,
          transition: "all 0.2s",
        }}
      >
        <Content className="bg-cmsBg flex flex-col min-h-screen overflow-x-hidden">
          <div className="centered-container flex-1 my-8">
            <Outlet />
          </div>
        </Content>
      </AntDLayout>
    </AntDLayout>
  );
}
