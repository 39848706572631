import logo from "../../assets/logo-dark.svg";

export default function WelcomeSplash() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-accent-gradient hidden w-3/5 flex-col px-[4.5rem] py-[2.75rem] lg:flex">
      <img
        src={logo}
        alt="Dsquares"
        className="flex h-[2.3125rem] w-[12.25rem] flex-shrink-0 flex-col pb-0"
      />
      <h1 className="mt-[11.94rem] text-3xl font-normal text-[#230871]">
        Welcome to Dsquares!
      </h1>
      <h1 className="mt-[1.25rem] max-w-[38rem] text-5xl font-medium text-[#230871]">
        Keeping your customers loyal.
      </h1>
      <p className="mt-[0.5rem] max-w-[34rem] text-xl font-thin text-[#230871]">
        Join our worldwide network of merchant and unlock endless business
        opportunities and unparalleled growth potential.
      </p>
      <h6 className="mt-auto self-end text-16 font-medium text-[#230871]">
        © {currentYear} All Rights Reserved
      </h6>
    </div>
  );
}
