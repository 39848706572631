import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import Layout from "src/layout";
import Transactions from "src/pages/Transactions/Transactions";
import SplashScreen from "src/components/SplashScreen/SplashScreen";
import Login from "src/pages/Login/Login";
import ProtectedRoutes from "src/router/ProtectedRoutes";
import { useSelector } from "react-redux";
import { accessTokenSelector } from "src/store/slices/auth";

export default function AppRoute() {
  const accessToken = useSelector(accessTokenSelector);

  return (
    <Suspense fallback={<SplashScreen isLoading />}>
      <Routes>
        <Route
          path="/login"
          element={accessToken ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Transactions />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}
