import { useEffect, useState } from "react";
// import { useGetTokenQuery } from "../store";
import { Spin } from "antd";

export const AUTH_TOKEN_KEY = "AUTH_TOKEN_KEY";

function useGetTokenQuery(arg0: null) {
  // TODO: Remove this function and use the one from store.tsx
  return {
    data: "token",
    isLoading: false,
  };
}
export function withToken<P extends object>(Component: React.ComponentType<P>) {
  return function (props: P) {
    const { data: token, isLoading } = useGetTokenQuery(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
      if (token) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        setReady(true);
      }
    }, [token]);

    if (isLoading || !ready) {
      return (
        <Spin size="large" spinning={isLoading}>
          <div style={{ height: "100vh", width: "100vw" }} />
        </Spin>
      );
    }

    return <Component {...props} />;
  };
}
