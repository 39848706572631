import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import clsx from "clsx";

export default function ErrorAlert({
  message,
  className,
}: {
  message: string;
  className?: string;
}) {
  return (
    <Alert
      className={clsx("my-1 text-red-800", className)}
      icon={<ExclamationCircleOutlined />}
      message={message}
      type="error"
      showIcon
    />
  );
}
