import { Button, Modal, Radio, RadioChangeEvent } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import "src/index.css";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: ({ orderId, action }: { orderId: string; action: number }) => void;
  children: ReactNode;
  isLoadingCancel: boolean;
  isSuccessCancel: boolean;
};
export default function ConfirmModal({
  onClose,
  isOpen,
  onConfirm,
  children,
  isLoadingCancel,
  isSuccessCancel,
}: Props) {
  const [value, setValue] = useState<null | 1 | 2 | 3>(null);

  useEffect(() => {
    if (isSuccessCancel) {
      onClose();
    }
  }, [isSuccessCancel]);

  return (
    <Modal
      className="custom-modal"
      title="Title"
      centered
      width={600}
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      {children}
      <div className="mt-8 flex gap-4 justify-end">
        <Button
          htmlType="button"
          onClick={onClose}
          className="border-solid border-1 border-hbr-primary hover:bg-[#EBF0FF] text-hbr-primary text-16 font-medium inline-flex gap-2 items-center justify-center rounded-lg px-5 h-11"
        >
          Cancel
        </Button>

        <Button
          type="primary"
          disabled={isLoadingCancel}
          onClick={() => {
            onConfirm({ orderId: "s", action: 1 });
          }}
          className="disabled:bg-gray-400  bg-[#D14343] hover:bg-[#D4524D]  text-white text-16 font-medium inline-flex gap-2 items-center justify-center rounded-lg px-5 h-11"
        >
          {isLoadingCancel}
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

// function CancelTranscation() {
//   return <div>are you want yo cancel this transcation</div>;
// }
