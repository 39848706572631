import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import LoginApi from "src/store/apis/Login";
import { transactionsApi } from "src/store/apis/Transactions";
import auth, { loadUserFromLocalStorage } from "src/store/slices/auth";

export const store = configureStore({
  reducer: {
    auth: auth,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
  },
  preloadedState: {
    auth: loadUserFromLocalStorage(),
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      transactionsApi.middleware,
      LoginApi.middleware
    );
  },
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
