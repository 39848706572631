import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "src/store/apis/BaseApi";

type GetDashBoardTransactionsBody = {
  pageNumber: number;
  pageSize: number;
  filter?: string | null;
};

export const transactionsApi = createApi({
  reducerPath: "transactions",
  baseQuery: baseQuery,
  tagTypes: ["Transcations"],
  endpoints: (builder) => ({
    getDashboardTransactions: builder.query({
      query: (body: GetDashBoardTransactionsBody) => ({
        url: "/Dashboard/GetTransactions",
        method: "POST",
        body,
      }),
      providesTags: ["Transcations"],
      transformResponse: (response: any) => {
        return response.result;
      },
    }),
    CancelTranscation: builder.mutation({
      query: ({ orderId }: { orderId: string }) => ({
        url: "Dashboard/CancelTransactions",
        method: "POST",
        body: {
          data: {
            orderId: [orderId],
          },
        },
      }),
      invalidatesTags: ["Transcations"],
      transformResponse: (response: any) => {
        return response.result;
      },
    }),
  }),
});

export const {
  useGetDashboardTransactionsQuery,
  useCancelTranscationMutation,
} = transactionsApi;
