import { Radio, RadioChangeEvent } from "antd";
import { useState } from "react";

export default function SentTranscation({
  handleRadioChange,
}: {
  handleRadioChange: (value: string) => void;
}) {
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(+e.target.value);
    handleRadioChange(e.target.value);
  };
  return (
    <>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={1}>subscribe without detuct</Radio>
        <Radio value={2}>sent Email</Radio>
        <Radio value={3}>sent SMS</Radio>
      </Radio.Group>
    </>
  );
}
