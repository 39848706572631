import { Button, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { DatabaseOutlined, LogoutOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import dsquaresLogo from "src/assets/logo-dark.svg";
import smallDsquaresLogo from "src/assets/logo192.png";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { logout } from "src/store/slices/auth";

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const items = [
  {
    key: "1",
    icon: <DatabaseOutlined />,
    label: <NavLink to="/">Transactions</NavLink>,
  },
];

export default function SideBar({ collapsed, setCollapsed }: SideBarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="overflow-auto fixed h-full bottom-11"
      width={250}
      style={{
        left: 0,
        top: 0,
        zIndex: 998,
        background: "#fff",
        height: "100vh",
      }}
    >
      <div className="p-5 pb-11 bg-white">
        <NavLink to={"/"} className="flex-shrink-0">
          <img
            src={collapsed ? smallDsquaresLogo : dsquaresLogo}
            alt="Dsquares"
            className="h-8 transition-all duration-300"
          />
        </NavLink>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{ height: "calc(100% - 80px - 32px)", borderRight: 0 }}
        items={items}
      />

      <div
        className={clsx(
          "fixed bottom-0 left-0 flex justify-around",
          collapsed ? " w-[80px] " : " w-[250px] "
        )}
      >
        <Button
          size="small"
          type="text"
          icon={
            collapsed ? (
              <LogoutOutlined className="text-24" />
            ) : (
              <div>
                <LogoutOutlined /> logout
              </div>
            )
          }
          onClick={(props) => {
            localStorage.removeItem("AUTH_TOKEN_KEY");
            localStorage.removeItem("auth_token");
            dispatch(logout());
          }}
          className={clsx({
            "h-11  rounded-none z-99998 bg-white hover:bg-slate-100": true,
          })}
          style={{
            transition: "all 0.2s",
          }}
        />
        <Button
          size="small"
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          className={clsx({
            "h-11  rounded-none  bg-white hover:bg-slate-100": true,
          })}
          style={{
            transition: "all 0.2s",
            // width: collapsed ? "80px" : "250px",
          }}
        />
      </div>
    </Sider>
  );
}
