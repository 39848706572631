import { useState } from "react";
import { PageHeader } from "src/components/PageHeaders/PageHeaders";
import TransactionsGrid from "src/components/TransactionsGrid/TransactionsGrid";

const routes = [{ path: "/", breadcrumbName: "Transactions" }];

export default function Transactions() {
  const [searchValue, setSearchValue] = useState("");
  return (
    <>
      <PageHeader className="mb-6" routes={routes} />
      <TransactionsGrid />
    </>
  );
}
