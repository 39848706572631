import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import classNames from "classnames";

type Props = {
  routes: {
    path: string;
    breadcrumbName: string;
  }[];
  button?: React.ReactNode;
  className?: string;
};
function PageHeader(props: Props) {
  const { routes, button, className } = props;

  return (
    <div className={classNames("flex items-center justify-between", className)}>
      <Breadcrumb
        className="relative flex font-light [&_.ant-breadcrumb-separator:nth-last-child(2)]:font-semibold"
        separator={<span className="text-2xl text-breadcrumbs">{">"}</span>}
        items={routes}
        itemRender={(route, _, routes) => {
          const isLast = routes.at(-1) === route;
          return isLast ? (
            <span className="text-2xl font-semibold text-breadcrumbs">{route.breadcrumbName}</span>
          ) : (
            <Link to={route.path!} className="text-2xl font-light text-breadcrumbs hover:bg-transparent">
              {route.breadcrumbName}
            </Link>
          );
        }}
      />
      {button}
    </div>
  );
}

export { PageHeader };
