import { createSlice } from "@reduxjs/toolkit";
import UserModel from "src/models/UserModel";
interface AuthState {
  user: UserModel | null;
  accessToken: string | null;
  rememberMe: boolean;
}
export const loadUserFromLocalStorage = (): AuthState => {
  const token = localStorage.getItem("AUTH_TOKEN_KEY");
  return {
    user: null,
    accessToken: token,
    rememberMe: false,
  };
};

const initialState: AuthState = {
  user: null,
  accessToken: null,
  rememberMe: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      const user = {
        id: action.payload.id,
        name: action.payload.name,
        userName: action.payload.userName,
      };
      state.user = user;
      state.accessToken = action.payload.accessToken;
      state.rememberMe = action.payload.rememberMe;
      localStorage.setItem("AUTH_TOKEN_KEY", String(state.accessToken));
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.rememberMe = false;
      localStorage.removeItem("AUTH_TOKEN_KEY");
    },
  },
});

export const { login, logout } = authSlice.actions;
export const accessTokenSelector = (state: any) => state.auth?.accessToken;
export default authSlice.reducer;
